export const externalUrls = Object.freeze({
	existingSubscriber:
		'https://kalmbach.dragonforms.com/loading.do?omedasite=DSC_Renew',
	newsletterUnsubscribe:
		'https://apps.kalmbach.com/newsletters/Unsubscribe.aspx',
	newsletterChangeEmail:
		'https://apps.kalmbach.com/newsletters/ChangeEmail.aspx',
	privacyPolicy: 'https://www.kalmbach.com/copyrightpolicy',
	resetPassword:
		'https://kalmbach.dragonforms.com/loading.do?omedasite=kalmbach_ForgotPass',
	subscribe:
		'https://kalmbach.dragonforms.com/loading.do?omedasite=DSS_New&pk=DSF43D1',
	// subscribeRenew:
	// 	'https://kalmbach.dragonforms.com/loading.do?omedasite=DSC_Renew',
	subscribeMeterBanner:
		'https://kalmbach.dragonforms.com/loading.do?omedasite=DSS_New&pk=DSF43D8',
	subscribePaywallPrompt:
		'https://kalmbach.dragonforms.com/loading.do?omedasite=DSS_New&pk=DSF43D9',
	termsOfUse: 'https://www.kalmbach.com/copyrightpolicy'
})
